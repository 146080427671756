import * as React from 'react';

import styled from 'styled-components';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import BubbleTitle from './BubbleTitle';
import IconFilledCheckCircle from './icons/IconFilledCheckCircle';
import QuoteText from './QuoteText';

const Main = styled.div`
  .bullet-list {
    display: flex;
    margin-bottom: 12px;
    .bullet-list-icon {
      margin: 3px 15px 0 0;
      font-size: 24px;
      line-height: 0;
    }
    .bullet-list-text {
      font-size: 14px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0px;
    }
  }
  .section-convince {
    display: flex;
    .column {
      flex: 1;
      margin-right: 12px;
      &:last-child {
        margin-right: 0;
      }
    }
    .convince-tag {
      display: inline-flex;
      padding: 0 8px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0px;
      margin-bottom: 12px;
      border-radius: 4px;

      &.green {
        background: #aecfbb;
      }
      &.red {
        background: #f2ae9d;
      }
    }
    .convince-box {
      border: 1px solid #d2d3d9;
      margin-bottom: 12px;
      padding: 8px 12px;
      border-radius: 4px;

      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0px;
      text-align: left;

      .bold {
        font-weight: 600;
        line-height: 24px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media ${deviceBreakpoints.mobile}, ${deviceBreakpoints.tablet} {
    .section-convince {
      flex-direction: column;
      .column {
        margin-right: 0;
        margin-top: 32px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
`;

const SectionScholarshipGuaranteedOutput = () => {
  return (
    <Main>
      <BubbleTitle
        data={[
          { text: 'Hỗ trợ', type: 'bubble' },
          { text: 'học lại miễn phí', type: 'text' }
        ]}
      />
      <div className="txt-description content-row font-secondary">
        Khái niệm đảm bảo đầu ra, đang bị học viên hiểu lầm, nên DOL xin dùng
        thuật ngữ “hỗ trợ học lại miễn phí” thay cho “đảm bảo đầu ra”
      </div>

      <div className="section-convince content-row">
        <div className="column">
          <div className="convince-tag green">DOL thì ✊</div>
          <div className="convince-box">
            <span className="bold">Thứ nhất,</span> để đạt đầu ra thì phải đảm
            bảo chất lượng giảng dạy để tất cả học viên của khóa học đều đạt
            được mục tiêu đầu ra chứ không phải là học đi học lại đến khi nào
            đạt được đầu ra thì thôi. Việc đảm bảo chất lượng thông qua việc
            phát triển phương pháp, giáo trình, đào tạo giáo viên và cả xây hệ
            thống công nghệ hỗ trợ luyện tập ở nhà.
          </div>
          <div className="convince-box">
            <span className="bold">Thứ hai,</span> để đạt đầu ra thì phải kiểm
            tra đúng đầu vào, không phải hạ thấp đầu vào học hết khóa đạt đầu ra
            đúng bằng đầu vào là đảm bảo đầu ra.
          </div>
          <div className="convince-box">
            <span className="bold">Thứ ba,</span> để đạt đầu ra cả DOL và học
            viên đều phải phối hợp với nhau. DOL dạy tậm tâm, phương pháp thông
            mình thì học viên cũng phải chịu đi học và làm bài đầy đủ. Kết quả
            không nào chỉ đến từ nỗ lực một phía.
          </div>
        </div>
        <div className="column">
          <div className="convince-tag red">Thường thì 🤡</div>
          <div className="convince-box">
            Đảm bảo đầu ra kiểu dạy thông thường không mang tính đột phá về
            phương pháp, giáo trình, công nghệ, rồi cho học viên học đến khi nào
            đạt đầu ra thì thôi. Có học lại kiểu này cũng chán, mất thời gian,
            và không mang lại gì.
          </div>
        </div>
      </div>

      <div className="txt-description content-row font-secondary">
        Vì vậy, tại DOL không có chính sách đảm bảo đầu ra, mà thay vào đó là
        chính sách hỗ trợ học lại miễn phí.
      </div>

      <div className="quote-section content-row">
        <QuoteText />
      </div>

      <div className="txt-description content-row font-secondary">
        Tuy nhiên, trong trường hợp một số bạn không may mắn khi đi thi thật do
        tâm lý, DOL có chính sách hỗ trợ sau khóa học cho các bạn bằng cách:
      </div>

      <div className="content-row font-secondary">
        <div className="bullet-list">
          <div className="bullet-list-icon">
            <IconFilledCheckCircle />
          </div>
          <div className="bullet-list-text">
            Cùng học viên tìm nguyên nhân và đưa ra giải pháp riêng cho từng
            bạn.
          </div>
        </div>

        <div className="bullet-list">
          <div className="bullet-list-icon">
            <IconFilledCheckCircle />
          </div>
          <div className="bullet-list-text">
            Học lại miễn phí đến khi đạt kết quả
          </div>
        </div>
      </div>
    </Main>
  );
};

export default SectionScholarshipGuaranteedOutput;
